import { StyledButton } from '@monbanquet/crumble'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'

const LargeBanner = ({
  picture,
  pictureClipped = false,
  children,
  ...props
}) => (
  <StyledLargeBanner {...props} hasPicture={!!picture}>
    {picture && typeof picture === 'object' && (
      <div className="picture">
        <StyledImg
          clipped={pictureClipped}
          fluid={picture.fluid}
          alt={picture.title}
        />
      </div>
    )}
    {picture && typeof picture === 'string' && (
      <div className="picture">
        <StyledImgOnly src={picture} alt="" />
      </div>
    )}
    <div className="wrapper">
      <div className="text-wrapper">{children}</div>
    </div>
  </StyledLargeBanner>
)

const StyledImg = styled(Img)`
  height: 100%;
  margin-left: auto;
  max-width: 760px;
  width: 50%;
  clip-path: ${({ clipped }) =>
    clipped ? 'polygon(38% 0, 100% 0, 100% 100%, 0% 100%)' : 'none'};

  & img {
    right: 0;
    left: unset !important;
    object-fit: ${({ clipped }) => (clipped ? 'cover' : 'contain')} !important;
    object-position: left 70% !important;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    width: 40%;
    transform: ${({ clipped }) =>
      clipped
        ? 'none'
        : 'rotate3d(0,0,1,270deg) translate3d(0,-120px,0) scale3d(1.5,1.5,1)'};

    & img {
      top: 15% !important;
    }
  }
`

const StyledImgOnly = styled.img`
  display: block;
  height: 100%;
  margin-left: auto;
  max-width: 760px;
  width: 50%;
  clip-path: ${({ clipped }) =>
    clipped ? 'polygon(38% 0, 100% 0, 100% 100%, 0% 100%)' : 'none'};
`

const StyledLargeBanner = styled.section`
  position: relative;
  height: 550px;
  display: flex;
  align-items: center;
  color: ${colors.text.dark};
  background-color: ${colors.background.semiLight};
  overflow: hidden;

  &::before {
    content: ${({ backgroundColor }) => (backgroundColor ? `' '` : `none`)};
    position: absolute;
    width: 50%;
    height: 100%;
    background: ${({ backgroundColor }) => backgroundColor};
    right: 0;
    bottom: 0;
    clip-path: polygon(38% 0, 100% 0, 100% 100%, 0% 100%);
    max-width: 760px;
  }

  .wrapper {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .text-wrapper {
    width: ${({ hasPicture }) => (hasPicture ? '50%' : '100%')};
  }

  h1 {
    font-size: 3.25rem;
    font-weight: 500;
    line-height: 3.8rem;
  }
  p {
    font-size: 1.2rem;
    margin-top: 30px;
    line-height: 1.9rem;
  }

  ${StyledButton} {
    margin-top: 30px;
  }

  .picture {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    align-self: center;
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .text-wrapper h1 {
      font-size: 2.9rem;
      line-height: 3.4rem;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .text-wrapper {
      width: ${({ hasPicture }) => (hasPicture ? '60%' : '100%')};
      h1 {
        font-size: 2.8rem;
        line-height: 3.4rem;
      }
    }
    height: 500px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    height: 450px;
    .text-wrapper {
      text-align: center;
      align-items: center;
      width: 100%;
      h1 {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    }
    .picture {
      display: none;
    }
  }
`

export default LargeBanner
export { StyledLargeBanner, StyledImg }
