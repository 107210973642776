import React from 'react'
import styled from 'styled-components'
import Link from '../components/Link'
import Navbar from '../components/Navbar'
import LargeBanner, { StyledLargeBanner } from '../components/LargeBanner'
import { breakpoints, colors, layout } from '../theme'
import Footer from '../components/Footer'
import Helmet from 'react-helmet'

class NotFoundPage extends React.Component {
  render() {
    return (
      <StyledNotFoundPage>
        <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
        <Navbar />
        <div className="page-content">
          <LargeBanner>
            <h1>
              <div>
                <span role="img" aria-label="baguette">
                  🥖
                </span>
              </div>
              <b>Flûte&nbsp;!</b>
            </h1>
            <h2>{`Cette page n'existe pas ou plus...`}</h2>
            <h2>
              {`Retourner vers `}
              <Link to="/">Monbanquet</Link>
            </h2>
          </LargeBanner>
        </div>
        <Footer />
      </StyledNotFoundPage>
    )
  }
}

const StyledNotFoundPage = styled.div`
  .page-content {
    margin-top: ${layout.navbarHeight.normal}px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledLargeBanner} {
    & > .wrapper {
      max-width: 1024px;
      width: calc(100% - 10vw);
      margin: 50px auto;
    }
    .text-wrapper {
      text-align: center;
    }
    a {
      color: ${colors.brand.normal};
    }
  }
  h2 {
    line-height: 1.5;
    margin-bottom: 15px;
  }
`

export default NotFoundPage
